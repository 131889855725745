<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">{{ $t(title) }}</h4>
        </div>
      </div>
      <div class="col-sm-6">

      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="mb-0">
              <div class="row">
                <div class="col-md-12 page-loader-parent">
                  <div class="page-loader">
                    <md-progress-spinner :md-diameter="50" :md-stroke="5" md-mode="indeterminate"></md-progress-spinner>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 d-none2 table-div">
                  <!-- <b-button variant="outline-dark" class="float-left pl-4 pr-4 pt-2 pb-2">
                    تصفية
                    <i class="ti ti-panel pl-2"></i>
                  </b-button> -->
                  <h4 class="font-size-18">{{ $t('Customer Chats') }}</h4>
                </div>
                <div class="col-md-6 d-none2 table-div">
                  <!-- <b-button pill variant="primary" class="float-right  pl-4 pr-4 pt-2 pb-2">
                    رسالة جديدة

                    <i class="ti ti-pencil-alt pl-2"></i>
                  </b-button> -->
                </div>
                <div class="col-md-12">
                  <hr>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 d-none2 table-div" style="min-height: 500px;">
                  <router-link :class="'contact-item-btn contact-' + item.user_type + '-' + item.user_id"
                    :to="'/chat-support/customers/' + item.user_id + '?type=' + item.user_type"
                    v-for="(item, index) in contacts" :key="index">
                    <div class="contact-item">
                      <div class="text-center">
                        <img src="../../../../assets/images/avatar.png" width="60">
                      </div>
                      <div class="contact-details">
                        <h6 v-if="item.user_type == 'business'" class="mb-1">{{ item.user?.employee?.first_name }} {{
                          item.user?.employee?.last_name }}</h6>
                        <h6 v-else class="mb-1">{{ item.user?.first_name }} {{ item.user?.last_name }}</h6>
                        <span v-if="item.lastMessage?.message_type == 'text'">
                          {{ item.lastMessage?.body }}
                        </span>
                        <span v-else-if="item.lastMessage?.message_type == 'image'">
                          <svg class="ml-2 mr-2" viewBox="0 0 16 20" height="20" width="16"
                            preserveAspectRatio="xMidYMid meet" version="1.1" x="0px" y="0px"
                            enable-background="new 0 0 16 20" xml:space="preserve">
                            <path fill="currentColor"
                              d="M13.822,4.668H7.14l-1.068-1.09C5.922,3.425,5.624,3.3,5.409,3.3H3.531 c-0.214,0-0.51,0.128-0.656,0.285L1.276,5.296C1.13,5.453,1.01,5.756,1.01,5.971v1.06c0,0.001-0.001,0.002-0.001,0.003v6.983 c0,0.646,0.524,1.17,1.17,1.17h11.643c0.646,0,1.17-0.524,1.17-1.17v-8.18C14.992,5.191,14.468,4.668,13.822,4.668z M7.84,13.298 c-1.875,0-3.395-1.52-3.395-3.396c0-1.875,1.52-3.395,3.395-3.395s3.396,1.52,3.396,3.395C11.236,11.778,9.716,13.298,7.84,13.298z  M7.84,7.511c-1.321,0-2.392,1.071-2.392,2.392s1.071,2.392,2.392,2.392s2.392-1.071,2.392-2.392S9.161,7.511,7.84,7.511z">
                            </path>
                          </svg>
                          Photo
                        </span>
                        <span v-else-if="item.lastMessage?.message_type == 'file'">
                          <svg class="ml-2 mr-2" viewBox="0 0 13 20" height="20" width="13"
                            preserveAspectRatio="xMidYMid meet" version="1.1" x="0px" y="0px"
                            enable-background="new 0 0 13 20" xml:space="preserve">
                            <path fill="currentColor"
                              d="M10.2,3H2.5C1.7,3,1,3.7,1,4.5v10.1C1,15.3,1.7,16,2.5,16h7.7c0.8,0,1.5-0.7,1.5-1.5v-10 C11.6,3.7,11,3,10.2,3z M7.6,12.7H3.5v-1.3h4.1V12.7z M9.3,10H3.5V8.7h5.8V10z M9.3,7.3H3.5V6h5.8V7.3z">
                            </path>
                          </svg>
                          Document
                        </span>
                        <span v-else-if="item.lastMessage?.message_type == 'audio'">
                          <svg class="ml-2 mr-2" viewBox="0 0 12 20" height="20" width="12"
                            preserveAspectRatio="xMidYMid meet" version="1.1" x="0px" y="0px"
                            enable-background="new 0 0 12 20" xml:space="preserve">
                            <path fill="currentColor"
                              d="M6,11.745c1.105,0,2-0.896,2-2V4.941c0-1.105-0.896-2-2-2s-2,0.896-2,2v4.803 C4,10.849,4.895,11.745,6,11.745z M9.495,9.744c0,1.927-1.568,3.495-3.495,3.495s-3.495-1.568-3.495-3.495H1.11 c0,2.458,1.828,4.477,4.192,4.819v2.495h1.395v-2.495c2.364-0.342,4.193-2.362,4.193-4.82H9.495V9.744z">
                            </path>
                          </svg>
                          Audio
                        </span>
                      </div>
                      <div class="text-center" style="width: 8%;">
                        <p class="m-0">{{
                          new Date(item.lastMessage?.created_at).toLocaleTimeString('fr-FR', {
                            hour:
                              '2-digit', minute: '2-digit',
                            hour12: true
                          }) }}</p>
                        <span class="unreadCount">
                          <small v-if="item.unseen > 0">{{ item.unseen }}</small>
                        </span>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                      @page-click="handlePagination"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import $ from 'jquery';
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
export default {
  page: {
    title: "Conversations",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      contacts: [{}, {}, {}, {}, {}],
      title: "Conversations",
      currentPage: 1,
      rows: null,
      perPage: 10
    };
  },
  mounted() {
    $('.page-loader-parent').show();
    $('.table-div').hide();
    var url = `${this.$appUrl}/api/admin/support/get_contacts?perPage=${this.perPage}&page=${this.currentPage}`;
    this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
      this.contacts = response.data.contacts.data;
      $('.page-loader-parent').remove();
      $('.table-div').show();
    });
    this.$pusher.subscribe('notifications-channel').bind('new-notification', (data) => {
      var message = JSON.parse(data);
      $('.contact-' + message.from_user_id + '-' + message.from_user_id).remove();
      // contacts
      // array.concat(newLastElement)
      // this.$toast.open({
      //   message: data,
      //   type: 'error',
      //   position: 'top-left',
      //   duration: 2500
      // });
    });
  },
  methods: {
    handlePagination() {
      var url = `${this.$appUrl}/api/admin/users?page=${this.currentPage}&perPage=${this.perPage}`;
      this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
        this.tableData = response.data.users.data;
        this.rows = response.data.users.total;
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.contact-item-btn {
  display: block;
  border-bottom: 1px solid #ddd;

  &:hover {
    border-radius: 8;
    background: #f9f9f9;
  }

  &:last-child {
    border: none;
  }

  .contact-item {
    display: flex;
    gap: 30px;
    padding: 15px 15px;
    align-items: center;
    color: #000;


    .contact-details {
      width: 100%;

      span {
        svg {
          vertical-align: bottom;
          color: #8696;
        }
      }
    }
  }

}

.page-loader {
  position: absolute;
  margin: 0px auto;
  left: 0;
  right: 0;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  z-index: 10;
  text-align: center;

  .md-progress-spinner {
    top: 50%;
  }
}

.page-loader-parent:has(.page-loader) {
  position: relative;
  min-height: 500px;
}

.div:has(.page-loader-parent) {
  min-height: 350px;
}

.unreadCount {
  display: inline-block;

  small {
    color: #fff;
    background-color: #00a884;
    font-weight: 600;
    border-radius: 1.1em;
    line-height: 1;
    min-height: 1em;
    font-size: 0.75rem;
    min-width: 1.7em;
    padding-bottom: 0.4em;
    padding-top: 0.3em;
    display: inline-block;
    padding-left: 0.4em;
    padding-right: 0.4em;
    text-align: center;
    vertical-align: -webkit-baseline-middle;
  }
}
</style>